import { AnnotationSet, AnnotationDesc, Annotation, DocMeta, OptionsT } from './types';

export function LoadAnnes(anne_metas: AnnotationDesc[], doc_meta: DocMeta){
  var filtered_annes = anne_metas.filter(x => x.jurisdiction == doc_meta.jurisdiction && x.slug == doc_meta.slug && x.version == doc_meta.version && x.language == doc_meta.language);
  var annes = [] as AnnotationSet[];
  for(var i = 0; i < filtered_annes.length; i++){
    var temp = LoadAnne(filtered_annes[i].name);
    if( temp ){
      annes.push(temp);
    }
  }
  return annes;
}

export function LoadAnne(name: string){
  try{
    var temp = JSON.parse(localStorage.getItem(LocalStorageAnneNameConstructor(name)) || '');
    if( temp ) return temp;
  }catch(err){
    //anne_metas = anne_metas.filter(x => x.name != filtered_annes[i].name);
    console.log(name, err)
    //localStorage.setItem("anns_meta", JSON.stringify(anne_metas));// removes from the meta
  }
  return null;
}

export function LocalStorageAnneNameConstructor(anne_name: string){
  return 'anne-'+anne_name;
}

export function DocumentID(dm: DocMeta){
  return dm.jurisdiction+'_'+dm.slug+'_'+dm.version+'_'+dm.language;
}

export function SaveOptions(op: OptionsT){
  localStorage.setItem("anns_options", JSON.stringify(op));
}

export function SaveDefaultAuthor(author: string){
  if( window.app.options.author_default != author && author.length > 0){
    window.app.options.author_default = author;
    SaveOptions(window.app.options);
    const anneAuthor = document.querySelector('#inputauthor') as HTMLTextAreaElement;
    if(anneAuthor) anneAuthor.value = window.app.options.author_default;
  }
}

export function CreateAnnotationSet(name: string, author: string, importDesc: DocMeta | null, annotations: Annotation[] | null){
  if( !name ){
    name = DocumentID(window.app.doc_meta); 
  }

  if(window.app.anns_meta.findIndex(a => a.name == name) > -1){
    var setId = 0;
    var newName = false;
    do {
      setId += 1;
      newName = window.app.anns_meta.findIndex(a => a.name == name+'-'+setId) == -1;
    } while (!newName);
    name = name+'-'+setId;
  }

  window.app.anns_meta.push({
    name: name,
    jurisdiction: importDesc ? importDesc.jurisdiction : window.app.doc_meta.jurisdiction,
    slug: importDesc ? importDesc.slug : window.app.doc_meta.slug,
    language: importDesc ? importDesc.language : window.app.doc_meta.language,
    version: importDesc ? importDesc.version : window.app.doc_meta.version
  } as AnnotationDesc);
  /* save into meta collection */
  localStorage.setItem("anns_meta", JSON.stringify(window.app.anns_meta));
  /* if not imported */
  if( !importDesc ) SaveDefaultAuthor(author);
  
  /* save new set */
  var anneSet = {
    name: name,
    jurisdiction: importDesc ? importDesc.jurisdiction : window.app.doc_meta.jurisdiction,
    slug: importDesc ? importDesc.slug : window.app.doc_meta.slug,
    language: importDesc ? importDesc.language : window.app.doc_meta.language,
    version: importDesc ? importDesc.version : window.app.doc_meta.version,
    au: author,
    annotations: importDesc ? annotations : [],
    open: true
  } as AnnotationSet;
  for(var i = 0; i < window.app.anns.length; i++){
    window.app.anns[i].open = false;
  }
  window.app.anns.push(anneSet);
  localStorage.setItem(LocalStorageAnneNameConstructor(name), JSON.stringify(anneSet));
  return name;
}

export function CreateAnnotation(annotation: Annotation){
  var set = window.app.anns.find( s => s.open );
  if( !set && window.app.anns.length > 0 ){
    window.app.anns[0].open = true;
    set = window.app.anns[0];
  }
  if( set ){
    var newName = false;
    var anneId = set.annotations.length > 0 ? +set.annotations[set.annotations.length-1].id : 0;
    do {
      anneId += 1;
      newName = set.annotations.findIndex(a => +a.id == anneId) == -1;
    } while (!newName);
    annotation.id = anneId+'';
    set.annotations.push(annotation);
    localStorage.setItem(LocalStorageAnneNameConstructor(set.name), JSON.stringify(set));
    SaveDefaultAuthor(annotation.au);
    return anneId;
  }
  return null;
}

export function EditAnnotation(annotation: Annotation){
  var set = window.app.anns.find( s => s.open );
  if( set ){
    var j = set.annotations.findIndex( x => x.id == annotation.id);
    set.annotations[j].a = annotation.a;
    set.annotations[j].au = annotation.au;
    set.annotations[j].n = annotation.n;
    set.annotations[j].r = annotation.r;
    set.annotations[j].t = annotation.t;
    set.annotations[j].u = annotation.u;
    /* little safety plan if bid is null */
    for(var i = 0; i < set.annotations.length; i++){
      if( set.annotations[i].bid.length == 0){
        set.annotations[i].bid = annotation.bid;
      }
    }
    localStorage.setItem(LocalStorageAnneNameConstructor(set.name), JSON.stringify(set));
    SaveDefaultAuthor(annotation.au);
  }
}

export function DeleteAnnotation(anneid: string){
  var set = window.app.anns.find( s => s.open );
  if( set ){
    set.annotations = set.annotations.filter(x => x.id != anneid);
    localStorage.setItem(LocalStorageAnneNameConstructor(set.name), JSON.stringify(set));
    var anne = document.getElementById('anne-'+anneid);
    if( anne ) anne.remove();
  }
}

export function UpdateAnnotationSetName(oldname: string, newname: string){
  var set = window.app.anns.find( s => s.name == oldname );
  var set2 = window.app.anns_meta.findIndex( s => s.name == oldname);
  var exists = window.app.anns_meta.findIndex( s => s.name == newname);
  if( set && set2 > -1 && exists == -1){
    set.name = newname;
    window.app.anns_meta[set2].name = newname;
    localStorage.setItem("anns_meta", JSON.stringify(window.app.anns_meta));
    localStorage.setItem(LocalStorageAnneNameConstructor(set.name), JSON.stringify(set));
    localStorage.removeItem(LocalStorageAnneNameConstructor(oldname));
    return newname
  }else{
    return oldname;
  }
}

export function DeleteAnnotationSet(name: string){
  var s = window.app.anns_meta.find( s => s.name == name );
  if( s ){
    window.app.anns_meta = window.app.anns_meta.filter(x => x.name != name);
    window.app.anns = window.app.anns.filter( x => x.name != name );
    localStorage.setItem("anns_meta", JSON.stringify(window.app.anns_meta));
    localStorage.removeItem(LocalStorageAnneNameConstructor(name));
  }else{
    console.log(name, s)
  }
}

export function AnnotationKBSize(name: string){
  var storname = LocalStorageAnneNameConstructor(name);
  if( localStorage[storname].length ){
    return (((localStorage[storname].length + storname.length) * 2) / 1024).toFixed(2) + " KB"
  }else{
    return "?? KB"
  }
}

/* not yet used */
export function TotalAnnotationKBSize(){
  var total = 0;
  for (var item in localStorage) {
    if (!localStorage.hasOwnProperty(item)) continue;
    total += ((localStorage[item].length + item.length) * 2);
  };
  return (total / 1024).toFixed(2) + " KB"
}

export function ImportAnnotationSet(file: File, callback: (name: string, success: boolean, report: string) => void) {
  const reader = new FileReader();
  reader.readAsText(file, 'UTF-8');
  reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
    if (readerEvent?.target?.result) {
      var contents = readerEvent.target.result.toString();
      var isJson = JSON.parse(contents);
      HandleImport(isJson, callback);
    }
  }
}

export const HandleImport = function(json: any, callback: (name: string, success: boolean, report: string) => void){
  var checkResult : AnnotationSet = CheckContents(json);
  if( checkResult.name != 'notvalid'){
    var meta : DocMeta = {
      jurisdiction: checkResult.jurisdiction,
      slug: checkResult.slug,
      language: checkResult.language,
      version: checkResult.version
    }
    checkResult.name = checkResult.name != 'noname' ? checkResult.name : DocumentID(meta);
    var indexExisting = window.app.anns_meta.findIndex( x => x.jurisdiction == meta.jurisdiction && x.slug == meta.slug && x.version == meta.version);
    if( indexExisting > -1){
      callback(checkResult.name, false, 'Import failed, an annotation set for ' + meta.jurisdiction + '/' + meta.slug + '/' + meta.version + '/ already exists. Currently this tool only allows one annotation set per document version. Export the existing one ('+window.app.anns_meta[indexExisting].name+') above, then delete it to import this file.');
    }else{
      CreateAnnotationSet(checkResult.name, checkResult.au, meta, checkResult.annotations);
      callback(checkResult.name, true, 'Import successful for: ' + checkResult.name);
    }
  }else{
    console.log('Invalid annotation set');
    callback(checkResult.name, false, 'Import unsuccessful');
  }
}

export const CheckContents = function(json: any) : AnnotationSet{
  var newSet : AnnotationSet;
  if( json.jurisdiction && json.slug && json.language && json.version ){
    var setName = json.name ? json.name : 'noname';
    var author = json.author ? json.author : '';
    newSet = {
      name: setName,
      jurisdiction: json.jurisdiction,
      slug: json.slug,
      language: json.language,
      version: json.version,
      au: author,
      annotations: [],
      open: true
    }
    function nullorundefined(obj: object){
      return obj == null || obj == undefined;
    }
    if( json.annotations && json.annotations.length ){
      for(var i = 0; i < json.annotations.length; i++ ){
        if( nullorundefined(json.annotations[i].id) ){
          console.log('Missing id field on annotation:', json.annotations[i]);
        }else if( nullorundefined(json.annotations[i].bid) ){
          console.log('Missing block id (bid) field on annotation:', json.annotations[i]);
        }else if( nullorundefined(json.annotations[i].a) ){
          console.log('Missing annotation (a) field on annotation:', json.annotations[i]);
        }else if( nullorundefined(json.annotations[i].a) ){
          console.log('Missing author (au) field on annotation:', json.annotations[i]);
        }else{
          newSet.annotations.push(json.annotations[i]);
        }
      }
    }
    return newSet;
  }else{
    return {
      name: 'notvalid',
      jurisdiction: '',
      slug: '',
      language: '',
      version: '',
      au: '',
      annotations: [],
      open: false
    }
  }
}