import { hasClass, addClass, delClass } from './utils'
import { StudyToggle, StudyUpdate } from './studyblock';
import { tinykeys } from "tinykeys";

export function SwapCurrent(newGlow: HTMLElement | null, updateHash: boolean){
  var theSel = document.querySelector('.block.glow') as HTMLElement;
  if( theSel ) delClass(theSel, 'glow');
  if( newGlow ){ 
    addClass(newGlow, 'glow');
    /* set the address bar hash */
    if( newGlow.id ){
      var compared = document.querySelector('#compared #' + newGlow.id);
      if( !compared ){
        var referencer = document.querySelector('app-referencer') as HTMLElement;
        referencer.setAttribute('blockid',newGlow.id);
      }else{
        var referencer = document.querySelector('app-referencer') as HTMLElement;
        referencer.setAttribute('blockid','');
      }
      if( updateHash ) window.location.hash = '#'+newGlow.id;
      StudyUpdate(newGlow.id);
    }
    ScrollToBlock(newGlow);
  }else{
    window.history.pushState({}, '', window.location.origin+window.location.pathname+window.location.search);
    StudyUpdate('');
  }
}

export function ScrollToBlock(newGlow: HTMLElement | undefined){
  if( !newGlow ) newGlow = document.querySelector('.block.glow') as HTMLElement;
  if( newGlow ){
    const y = newGlow.getBoundingClientRect().top + window.scrollY + -100;
    window.scrollTo({top: y, behavior: 'smooth'});
  }
}


export function CurrentBlock(){
  var theSel = document.querySelector('.block.glow') as HTMLElement;
  if(!theSel && window.location.hash) {
    var isLegit = document.getElementById(window.location.hash.replace('#',''));
    if( isLegit && hasClass(isLegit,'block') ){
      theSel = isLegit;
      window.location.hash = '#'+theSel.id;
      StudyUpdate(theSel.id);/* remove line when form not opening on default */
    }
  }
  if( !theSel ) theSel = document.querySelector('.block') as HTMLElement;
  if( theSel ) addClass(theSel, 'glow');
  return theSel;
}

export function Navigator(){
  let nodes : HTMLElement[] = [];
  let element = CurrentBlock();
  if( element ){
    while (element.parentElement) {
      if (hasClass(element.parentElement, 'block')) {
        nodes.unshift(element.parentElement);
      }
      element = element.parentElement;
    }
    var preferredIndent = nodes.length;
    var actualIndent = nodes.length;

    document.addEventListener("keydown", navTime);

    tinykeys(window, {
      "Enter": (e) => {
        e.preventDefault();
        e.stopPropagation();
        var studyBlock = document.querySelector('app-studyblock') as HTMLElement;
        if( !hasClass(studyBlock, 'open') ){
          StudyToggle('');
        }else{
          StudyToggle('');
        }
      },
    });

    tinykeys(window, {
      "Escape": (e) => {
        e.preventDefault();
        e.stopPropagation();
        var studyBlock = document.querySelector('app-studyblock') as HTMLElement;
        if( hasClass(studyBlock, 'open') ){
          StudyToggle('');
        }
      },
    });
  }
  
  function navTime(e){
    const { key, target } = e;
    var tagName = target.tagName.toLowerCase();
    if( tagName != 'textarea' && tagName != 'input' ){
      if( key === '#' ){
        console.log('Open search?');
      } else if( key === 'w'){// || key === 'ArrowUp'
        selectPrevious(CurrentBlock(), false);
      } else if( key === 's'){// || key === 'ArrowDown' 
        selectNext(CurrentBlock(), false);
      } else if( key === 'a'){// || key === 'ArrowLeft'
        selectOut(CurrentBlock(), true);
      } else if( key === 'd'){// || key === 'ArrowRight'
        selectIn(CurrentBlock(), false, true);
      }
    }
  }


  function selectNext(e: HTMLElement, noloopback: boolean){
    var nextSib = findNextSibling(e);
    if( preferredIndent == actualIndent || noloopback){
      if( nextSib ){
        SwapCurrent(nextSib, true);
      }else{
        nextSib = e.parentElement;
        if( nextSib ){
          if( actualIndent > 0 && hasClass(nextSib,'block'))actualIndent--;
          selectNext(nextSib, true)
        }
      }
    }else if( preferredIndent < actualIndent ){
      var nextElement = e.nextElementSibling as HTMLElement;
      if( nextElement == null ){
        selectOut(e, false);
      }else if(nextElement.nodeName.toLowerCase() == 'ol' || nextElement.nodeName.toLowerCase() == 'ul'){
        selectIn(e, false, false)
      }else{
        if( nextSib ) SwapCurrent(nextSib, true)
      }
    }else if( preferredIndent > actualIndent ){
      selectIn(e, false, false);
    }
  }

  function selectPrevious(e: HTMLElement, noloopback: boolean){
    var prevSib = findPreviousSibling(e);
    if( preferredIndent <= actualIndent || noloopback){
      if( prevSib ){
        SwapCurrent(prevSib, true);
      }else{
        prevSib = e.parentElement as HTMLElement;
        if( prevSib ){
          if( actualIndent > 0 && hasClass(prevSib, 'block') ){
            actualIndent--;
            SwapCurrent(prevSib, true);
          }else{
            selectPrevious(prevSib, true);
          }
        }
      }
    }else if( preferredIndent > actualIndent ){
      if( prevSib ){
        var prevlastchild = findFurtherestChild(prevSib);
        if(prevlastchild){
          if( hasClass(prevSib, 'block') ) actualIndent++;
          SwapCurrent(prevlastchild, true);
        }else if( hasClass(prevSib, 'block') ){
          SwapCurrent(prevSib, true);
        }
      }else{
        var nextUp = findClosestParent(e);
        if( nextUp ){
          SwapCurrent(nextUp, true);
        }
      }
    }
  }

  function selectIn(e: HTMLElement, previous: boolean, increment: boolean){
    var firstCh = findClosestChild(e);
    if( firstCh ){
      if( increment )preferredIndent++;
      SwapCurrent(firstCh, true);
    }else{
      if(previous){
        selectPrevious(e, true);
      }else{
        selectNext(e, true);
      }
    }
  }

  function selectOut(e: HTMLElement, previous: boolean){
    var firstPar = findClosestParent(e);
    if( firstPar ){
      if( preferredIndent > 0 ) preferredIndent--;
      SwapCurrent(firstPar, true);
    }else{
      if(previous){
        selectPrevious(e, true);
      }else{
        selectNext(e, true);
      }
    }
  }

  function findClosestChild(e: HTMLElement){
    var nextChild = e.querySelector(".block") as HTMLElement;
    if( nextChild ){
      actualIndent++;
      return nextChild;
    }
    return null;
  }

  function findFurtherestChild(e: HTMLElement){
    var childs = e.querySelectorAll(".block") as NodeListOf<HTMLElement>;
    if( childs ){
      return childs[childs.length-1];
    }
    return null;
  }
  
  function findClosestParent(e: HTMLElement){
    var nextPar = e.parentElement as HTMLElement;
    if( nextPar ){
      if( actualIndent > 0 )actualIndent--;
      if( hasClass(nextPar, 'block') ){
        return nextPar;
      }else {
        return findClosestParent(nextPar);
      }
    }
    return null;
  }

  function findNextSibling(e: HTMLElement){
    var nextElement = e.nextElementSibling as HTMLElement;
    while (nextElement) {
      if (hasClass(nextElement, 'block')){
        return nextElement;
      }
      if(nextElement.nodeName.toLowerCase() == 'ol' || nextElement.nodeName.toLowerCase() == 'ul'){
        return findClosestChild(nextElement);
      }
      nextElement = nextElement.nextElementSibling as HTMLElement;
    }
    return null;
  }

  function findPreviousSibling(e: HTMLElement){
    var prevElement = e.previousElementSibling as HTMLElement;
    while (prevElement) {
      if (hasClass(prevElement, 'block')){
        return prevElement;
      }
      if(prevElement.nodeName.toLowerCase() == 'ol' || prevElement.nodeName.toLowerCase() == 'ul'){
        return findFurtherestChild(prevElement);
      }
      prevElement = prevElement.previousElementSibling as HTMLElement;
    }
    return null;
  }
}

