import { AnnotationSet, Tag } from './types';
import { addClass, delClass, hasClass, alterURL } from "./utils";
import { ScrollToBlock } from './navigator';
import { StudyToggle } from './studyblock';
import { ActiveAnne, removeFilteredClass, addFilteredClass } from './annotations/utils';
import { Sorter, HeightCalculator } from './annotations/collector';
import { AnnotationGenerator } from './annotations/generator';

export default class Annotations extends HTMLElement {

  activeset: AnnotationSet | null;
  editing: boolean;
  compare: boolean;
  tag: Tag;

  dom_grid: HTMLElement;
  dom_article: HTMLElement;
  dom_loveletter: HTMLElement;

  url_params: URLSearchParams;

  static get observedAttributes() {
    return ["latest", "redraw"];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    var firstDraw = false;
    
    if( name == 'latest' && newValue ){
      if( newValue.indexOf('anneaddtags|') > -1 ){
        var newtags = newValue.split('|')[1].split(',');
        // check if annotation added has no tags currently filtered and if so then reset filter.
        if( !this.tagsInFilter(newtags) ){
          var clearfilter = document.querySelector('#clearfilter') as HTMLElement;
          this.emptyFilter(clearfilter);
        }
      }

      var set = ActiveAnne(window.app.anns);
      if( set && newValue.indexOf('deleteset') == -1 ){
        if( this.dom_grid && !hasClass(this.dom_grid, "annotate_col") ){
          this.gettingAnnotations();
          firstDraw = true;
        }
      }
      if( newValue.indexOf('deleteset') == 0){
        if( this.dom_grid ) this.losingAnnotations();
        this.activeset = null;
      }else{
        this.activeset = set;
      }
      if( newValue.indexOf('loadset') == 0 && set){
        this.activeset = set;
        this.runRender();
      }
      this.timeRender(false, false);
    }
    if( (name == 'redraw' && newValue) || firstDraw){
      this.repositionAnnotations();
      if( newValue.indexOf('noscroll') == -1 ){
        setTimeout(() => {
          ScrollToBlock(document.querySelector('.block.glow') as HTMLElement);
        }, 400);
      }
    }
  }

  connectedCallback() {
    this.dom_grid = document.querySelector('#gridup') as HTMLElement;
    this.dom_article = document.querySelector('article') as HTMLDivElement;
    this.dom_loveletter = document.querySelector('#loveletter') as HTMLElement;
    this.url_params = new URLSearchParams(window.location.search);
    this.tag = {
      preset: '',
      tags: [],
      filter: this.url_params.get('tags')?.split(',') || []
    }

    var preanne = this.url_params.get('annotation');
    this.editing = true;
    this.compare = this.url_params.get('compare') != null;

    var t = this;
    document.onreadystatechange = function () {
      /* add mobile view */
      var mobileAnne = document.querySelector('#mobile-anne') as HTMLElement;
      if( !mobileAnne ){
        var elemDiv = document.createElement('div');
        elemDiv.classList.add('mobile-anne');
        elemDiv.classList.add('overlayup');
        elemDiv.id = 'mobile-anne';
        document.body.appendChild(elemDiv);
        mobileAnne = document.querySelector('#mobile-anne') as HTMLElement;
      }
      /* we need .ll height from top of page to subtract */
      if (document.readyState == "complete") {
        if( preanne ){
          t.editing = false;
          var studyblock = document.querySelector('app-studyblock') as HTMLElement;
          studyblock.setAttribute('editing','false');
          fetch("/annotations/"+window.app.doc_meta.jurisdiction+"/" + window.app.doc_meta.slug + "/" +preanne+".json")
            .then(response => response.json())
            .then(json => {

              var x = json as AnnotationSet;
              if(window.location.pathname.toLowerCase() == ('/'+x.jurisdiction+'/'+x.slug+'/'+x.version+'/'+x.language+'/').toLowerCase()){
                t.activeset = x;
                t.tag.preset = preanne;
                t.runRender();
              }else{
                t.activeset = null;
                t.runRender();
              }
            }).catch( err => {
              console.log('annotation not found', err);
              t.activeset = ActiveAnne(window.app.anns);
              t.runRender();
            });
        } else {
          //t.editing = true;
          t.activeset = ActiveAnne(window.app.anns);
          t.runRender();
        }
      }
    }
  }

  gettingAnnotations(){
    addClass(this.dom_grid, "annotate_col");
    if( this.dom_article && hasClass(this.dom_article, "compare") ){
      delClass(this.dom_article, "compare");
    }
  }

  losingAnnotations(){
    delClass(this.dom_grid, "annotate_col");
    if( this.dom_article && hasClass(this.dom_grid, "compare_col") ){
      addClass(this.dom_article, "compare");
    }
    if ( this.dom_loveletter ) delClass( this.dom_loveletter, 'filtaged');
  }

  runRender(){
    var hasAnnotations = this.activeset && this.activeset.annotations && this.activeset.annotations.length > 0;
    if( !hasClass(this.dom_grid, "annotate_col") && hasAnnotations) {
      this.gettingAnnotations();
    }else if( hasClass(this.dom_grid, "annotate_col") && !hasAnnotations){
      this.losingAnnotations();
    }
    if( this.tag.filter.length > 0 ){
      addClass(this.dom_loveletter, 'filtaged');
    }else{
      delClass(this.dom_loveletter, 'filtaged');
    }
    this.timeRender(this.compare, true);
  }

  tagNav(){
    var toolset = document.querySelector('#toolset .tools') as HTMLElement;
    if( toolset && this.tag.tags && this.tag.tags.length > 0 ){
      var output = `<a class="tagfilter`+( this.tag.filter.length > 0 ? '' : ' hide')+`" href="#" title="Clear tag selection" aria-label="Clear tag selection" id="clearfilter">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
      </a>`;
      var anneqs = this.tag.preset ? `annotation=`+this.tag.preset+`&` : '';
      for(var i = 0; i < this.tag.tags.length; i++){
        output += `<a class="tagcatch`+(this.tag.filter.indexOf(this.tag.tags[i]) > -1 ? ' filtered': '')+`" data-filter="`+this.tag.tags[i]+`" href="?`+anneqs+`tags=`+this.tag.tags[i]+`&">#`+this.tag.tags[i]+`</a>`
      }
      var tagsexists = toolset.querySelector('.tags') as HTMLElement;
      if( tagsexists ){
        tagsexists.innerHTML = output;
      }else{
        toolset.insertAdjacentHTML('beforeend', '<div class="tags">'+output+'</div>');
      }
      var clearfilter = document.querySelector('#clearfilter') as HTMLElement;
      var tags = document.querySelectorAll('.tagcatch') as NodeListOf<HTMLElement>;
      var t = this;
      clearfilter.addEventListener('click', (e) => {
        e.preventDefault();
        t.emptyFilter(clearfilter);
        t.redraw(true);
      });
      tags.forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          t.filterChange(item);
        });
      });
    }else{
      toolset.querySelector('.tags')?.remove();
    }
    /* reset equivalent interface in form */
    const annos = document.querySelector('app-studyform') as HTMLElement;
    annos.setAttribute('latest', Math.random()+'-'+Math.random());
  }

  emptyFilter(clearfilter: HTMLElement){
    this.url_params.delete('tags');
    alterURL(this.url_params.toString());
    this.tag.filter = [];
    delClass(this.dom_loveletter, 'filtaged');
    if( clearfilter ) addClass(clearfilter, 'hide');
    setTimeout(() => {
      ScrollToBlock(document.querySelector('.block.glow') as HTMLElement);
    }, 400);
  }


  redraw(scroll: boolean){
    addClass(this.dom_grid, 'loading');
    this.timeRender(false, scroll);
  }

  tagsInFilter( tags: string[]){
    for( var i = 0; i < tags.length; i++){
      if( this.tag.filter.indexOf(tags[i]) > -1 ){
        return true;
      }
    }
    return false;
  }

  filterChange(item : HTMLElement){
    if( item.dataset.filter ){
      var clearfilter = document.querySelector('#clearfilter') as HTMLElement;
      if( this.tagsInFilter([item.dataset.filter]) ){
        var g = new URLSearchParams(window.location.search).get('tags');
        if( g != null ){
          var h = g.split(',');
          this.tag.filter = h.filter( x => x != item.dataset.filter);
          removeFilteredClass(item);
          if( this.tag.filter.length > 0 ){
            this.url_params.delete('tags');
            alterURL(this.url_params.toString()+'&tags='+this.tag.filter.join(',')+`&`);
          }else{
            this.emptyFilter(clearfilter);
          }
        }
      }else{
        delClass(clearfilter, 'hide');
        this.tag.filter.push(item.dataset.filter);
        this.url_params.delete('tags');
        alterURL(this.url_params.toString()+'&tags='+this.tag.filter.join(',')+`&`);
        addClass(this.dom_loveletter, 'filtaged');
        addFilteredClass(item);
      }
      this.redraw(true);
    }
  }

  timeRender(hide: boolean, scroll: boolean){
    if( this.activeset ){
      var collector = Sorter(this.activeset, this.tag);
      collector = HeightCalculator(collector, this.dom_loveletter.getBoundingClientRect().top, hide)

      this.innerHTML = AnnotationGenerator(collector, this.tag);
      this.annotationsEvents();
      var art = document.querySelector('.loading') as HTMLElement;
      if( art ) delClass(art, 'loading');

      this.tagNav();
      if( scroll ){
        setTimeout(() => {
          ScrollToBlock(document.querySelector('.block.glow') as HTMLElement);
        }, 400);
      }
    }else{
      var art = document.querySelector('.loading') as HTMLElement;
      if( art ) delClass(art, 'loading');
    }
  }

  repositionAnnotations(){
    if( this.activeset ){
      var collector = Sorter(this.activeset, this.tag);

      setTimeout(() => {
        collector = HeightCalculator(collector, this.dom_loveletter.getBoundingClientRect().top, false)
        for( var i = 0; i < collector.length; i++ ){
          var anno = document.querySelector('#annecol-'+collector[i].bid) as HTMLElement;
          if( anno ){
            var diff = Math.abs(+anno.style.top.replace('px','') - collector[i].yoffset);
            if( diff > 5 ){
              anno.style.top = Math.round(collector[i].yoffset)+'px';
            }
          }
        }
      }, 300);
    }
  }

  annotationsEvents(){
    var as = document.querySelectorAll('app-annotations .annotate') as NodeListOf<HTMLElement>;
    var t = this;
    as.forEach(function (item) {
      item.addEventListener('click', (e) => {
        if( t.editing ) StudyToggle(item.id);
        if( window.innerWidth <= 616 ){
          /* mobile view */
          var ma = document.querySelector('#mobile-anne') as HTMLElement;
          if( item.parentElement ){
            if( ma.dataset.id == item.parentElement.id && hasClass(ma, 'sup') ){
              delClass(ma, 'sup');
            } else {
              ma.innerHTML = '<a href="#" id="closema"></a>';
              ma.appendChild(item.parentElement.cloneNode(true));
              ma.dataset.id = item.parentElement.id;
              if( ma.firstChild ){
                (ma.firstChild as HTMLElement).removeAttribute('style');
                ma.firstChild.childNodes.forEach((item) => {
                  (item as HTMLElement).removeAttribute('style');
                  item.childNodes.forEach((it) => {
                    (it as HTMLElement).removeAttribute('style');
                  });
                });
              }
              if( !hasClass(ma, 'sup')) addClass(ma, 'sup');
              var cma = document.querySelector('#closema') as HTMLElement;
              cma.addEventListener('click', (e) => {
                e.preventDefault();
                delClass(ma, 'sup');
              });
              t.addTagClickEvents(document.querySelectorAll('#mobile-anne .tags a') as NodeListOf<HTMLElement>);
            }
          }
        }else{
          /* desktop view */
          if( hasClass(item, 'hover' ) ){
            delClass(item, 'hover');
            if( item.parentElement ) delClass(item.parentElement, 'hshover');
          }else{
            var hovs = document.querySelectorAll('.hover') as NodeListOf<HTMLElement>;
            hovs.forEach(function (it) {
              delClass(it, 'hover');
              if( it.parentElement ) delClass(it.parentElement, 'hshover');
            });
            addClass(item, 'hover');
            if( item.dataset.blockid ) t.relatedBlock(item.dataset.blockid);
            if( item.parentElement ) addClass(item.parentElement, 'hshover');
          }
        }
      });
    });
    as.forEach(function (item) {
      item.addEventListener('mouseover', () => {
        if( item.dataset.blockid ) t.relatedBlock(item.dataset.blockid);
      });
    });
    this.addTagClickEvents(document.querySelectorAll('app-annotations .annect .tags a') as NodeListOf<HTMLElement>);
  }

  addTagClickEvents(as: NodeListOf<HTMLElement>){
    if( as.length > 0 ){
      var t = this;
      as.forEach(function (item) {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          t.filterChange(item);
          e.stopPropagation();
        });
      });
    }
  }

  relatedBlock(id: string){
    var as = document.querySelector('.block.related') as HTMLElement;
    if( as ) delClass(as, 'related');
    var as2 = document.querySelector('#'+id) as HTMLElement;
    if( as2 ) addClass(as2, 'related');
  }
}
