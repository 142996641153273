export default class DarkMode extends HTMLElement {

  connectedCallback() {
    var checked = detectColorScheme() == "dark";
    this.innerHTML = `<button id="theme-toggle" title="Toggles between light &amp; dark mode" aria-label="`+(checked ? 'dark': 'light')+`" aria-live="polite">
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="moon">
        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
      </svg>
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="sun">
        <circle cx="12" cy="12" r="5"></circle>
        <line x1="12" y1="1" x2="12" y2="3"></line>
        <line x1="12" y1="21" x2="12" y2="23"></line>
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
        <line x1="1" y1="12" x2="3" y2="12"></line>
        <line x1="21" y1="12" x2="23" y2="12"></line>
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
      </svg> 
    </button>`;

    const toggle = document.querySelector('#theme-toggle') as HTMLButtonElement;
    if( toggle ){
      // class="sl-theme-dark"
      toggle.addEventListener("click", function (e) {
        theme.value = theme.value === 'light' ? 'dark' : 'light';
        setPreference();
      });
    }
  }
}

export const detectColorScheme = function() {
  var theme = 'light';
  if (localStorage.getItem("theme")) {
    if(localStorage.getItem("theme") == "dark") theme = 'dark';
  } else if(!window.matchMedia) {
    return 'light';
  } else if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //OS theme
    theme = 'dark';
  }
  return theme;
}

const setPreference = () => {
  localStorage.setItem('theme', theme.value)
  reflectPreference()
}

const reflectPreference = () => {
  document.firstElementChild?.setAttribute('data-theme', theme.value)
  document.querySelector('#theme-toggle')?.setAttribute('aria-label', theme.value)
}

const theme = {
  value: detectColorScheme(),
}

// set early so no page flashes
reflectPreference()

window.onload = () => {
  // set on load so screen readers can see latest value on the button
  reflectPreference()


}

window
  .matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', ({matches:isDark}) => {
    theme.value = isDark ? 'dark' : 'light'
    setPreference()
  })
