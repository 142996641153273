import { CreateAnnotationSet, CreateAnnotation, EditAnnotation, DeleteAnnotation, UpdateAnnotationSetName, ImportAnnotationSet, AnnotationKBSize, DeleteAnnotationSet, LoadAnne } from "./storage";
import { Annotation, AnnotationDesc, AnnotationSet } from "./types";
import { CleanUp, CleanUpBack, CleanUpTags, CleanUpBackArray, URLFriendly, hasClass, addClass, delClass } from './utils'
import { tinykeys } from "tinykeys";

export default class StudyForm extends HTMLElement {

  anneid: string;
  a: string;
  t: string[];
  u: string[];
  n: string[];
  r: string[];
  annotationsetname: string;
  deletesvg: string;
  exportsvg: string;


  static get observedAttributes() {
    return ["anneid", "latest"];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    const tags = this.querySelector('#inputtag') as HTMLInputElement;
    if( name == 'anneid' ){
      this.anneid = newValue;
      const anneTextArea = this.querySelector('#inputannotation') as HTMLTextAreaElement;
      const delebut = this.querySelector('#deletebutton') as HTMLButtonElement;
      const anneAuthor = this.querySelector('#inputauthor') as HTMLInputElement;
      const saveButtonText = this.querySelector('#savebutton span') as HTMLElement;

      if ( anneTextArea && newValue == '' ){
        anneTextArea.value = '';
        delebut.disabled = true;
        saveButtonText.innerHTML = 'Add';
      }

      var set = window.app.anns.find(s => s.open);
      if( set && this.anneid){
        var anne = set.annotations.find( a => a.id == this.anneid);
        if( anne ){
          this.a = anne.a;
          anneTextArea.value = CleanUpBack(anne.a);
          anneAuthor.value = CleanUpBack(anne.au);
          tags.value = CleanUpBackArray(anne.t);
          this.populateTagSelector(anne.t);
          saveButtonText.innerHTML = 'Update';
        }
        delebut.removeAttribute('disabled');
      }
    } else if( name == 'latest' ){
      this.populateTagSelector(CleanUpTags(tags.value));
    }
  }

  connectedCallback() {
    this.innerHTML = this.initialRender();
    this.initialEventSetup();
    this.annotationSetLoad();
  }

  initialRender(){
    this.deletesvg = `<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>`;
    this.exportsvg = `<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-database"><ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path></svg>`;
    var output = `<div class="tab-panel" name="annotations" role="tabpanel" aria-labelledby="tab_annotate">
        <textarea name="annotation" id="inputannotation" placeholder="Annotate here, 'Ctrl+Enter' to save."></textarea>
        <label for="inputauthor">Author: </label><input type="text" id="inputauthor" name="author" value="" placeholder="Author" maxlength="40" />
      </div>
      <div class="tab-panel" name="tags" role="tabpanel" aria-labelledby="tab_tag">
        <label for="inputtag">Tags: </label><span class="prefix">#</span><input type="text" id="inputtag" class="inputtag prefix" name="tag" placeholder="tag, tag2, tag3..." /><a href="#" id="cleartags" class="buttonink">Clear</a><input type="hidden" id="tags" name="tags"  />
        <div id="selectedtags" class="tags"></div>
      </div>`;
      /*
    output += `<div class="tab-panel" name="urls" role="tabpanel" aria-labelledby="tab_urls">
        <textarea name="annotation" id="urls"></textarea>
        <button class="button">Add new annotation</button>
      </div>`;
    output += `<div class="tab-panel" name="notable" role="tabpanel" aria-labelledby="tab_notable">
        <textarea name="annotation" id="notable"></textarea>
        <button class="button">Add new annotation</button>
      </div>`;
    output += `<div class="tab-panel" name="related" role="tabpanel" aria-labelledby="tab_related">
        <textarea name="annotation" id="related"></textarea>
        <button class="button">Add new annotation</button>
      </div>`;*/
    output += `<div class="tab-panel" name="save" role="tabpanel" aria-labelledby="tab_set">
        <div id="exportanne">
          <label for="inputsetname">Set Name: </label><input type="text" id="inputsetname" name="setname" value="" placeholder="Annotation Set Name" maxlength="40" />
          <div id="annestats"></div>
          <div class="buttons left">
            <div><button id="deletesetbutton" class="button delete" type="button" title="Delete">`+this.deletesvg+`</button></div>
            <button type="button" id="exportset" class="button export">`+this.exportsvg+` Export Set</button>
          </div>
        </div>
      </div>
      <div class="tab-panel" name="sets" role="tabpanel" aria-labelledby="tab_sets">
        <div id="annesets"></div>
        <div id="importanne">
          <label for="annotationimport">Import Set: </label><input type="file" id="annotationimport" name="jsonImport" accept=".json">
          <span class="report"></span>
        </div>
      </div>
      <div class="tab-panel" name="help" role="tabpanel" aria-labelledby="tab_guide">
        <h6 class="first">Expectations</h6>
        <p>This is a beta. Future updates may break your annotations. Everything you create stays in your browser, we cannot see it &amp; we cannot back it up for you. Export/import &amp; share with others. Tell us if you're using it; we're not tracking users. If you have a public document you'd like to annotate let us know.</p>
        <h6>Keyboard shortcuts</h6>
        <p>Like a gamer, the <b>w</b>, <b>a</b>, <b>s</b>, <b>d</b> keys let you navigate the document blocks. Tag real fast! <b>Ctrl+Enter (⌘+Return):</b> Save annotation, <b>Enter (Return):</b> Open editor, <b>Escape:</b> Close editor.</p>
        <h6>Tips</h6>
        <p>The tags input box doesn't reset. This allows you to add repeating tags quickly by navigating with the wasd keys and hitting Ctrl+Enter on each applicable block.</p>
      </div>
      <div class="buttons right" id="tabsave">
        <div></div>
        <div><button id="deletebutton" class="button delete" type="button" title="Delete" disabled>`+this.deletesvg+`</button></div>
        <button id="savebutton" class="button" type="submit" title="Add (Ctrl+Enter)"><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg><span>Add</span></button>
      </div>`;
    return output;
  }

  initialEventSetup(){
    const cleartags = this.querySelector('#cleartags') as HTMLElement;
    const savebut = this.querySelector('#savebutton') as HTMLElement;
    const delebut = this.querySelector('#deletebutton') as HTMLElement;
    const anneTextArea = this.querySelector('#inputannotation') as HTMLTextAreaElement;
    const anneAuthor = this.querySelector('#inputauthor') as HTMLInputElement;
    const inputSetName = this.querySelector('#inputsetname') as HTMLInputElement;
    const exportset = this.querySelector('#exportset') as HTMLButtonElement;
    const selectedtags = this.querySelector('#selectedtags') as HTMLDivElement;
    const deletesetbutton = this.querySelector('#deletesetbutton') as HTMLButtonElement;
    const tag = this.querySelector('#inputtag') as HTMLInputElement;

    var t = this;
    inputSetName.addEventListener("keyup", (e) => {
      const { key } = e;
      if( key === 'Enter' ){
        e.preventDefault();
        t.saveSetName(inputSetName, inputSetName.value);
        e.stopPropagation();
      }
    });
    inputSetName.addEventListener('blur', function() {
      if( inputSetName.value.trim().length > 0){
        t.saveSetName(inputSetName, inputSetName.value);
      }else{
        var set = t.getSet();
        if( set && set.name ) inputSetName.value = set.name;
      }
    });

    selectedtags.addEventListener('click', (e) => {
      var ele = e.target as HTMLElement;
      if( e.target && hasClass(ele, "tagcatch") ){
        var existingTags = CleanUpTags(tag.value);
        var newTag = (ele).id;
        var numb = existingTags.indexOf( newTag );
        if( numb == -1 ){
          addClass(ele, "filtered");
          existingTags.push(newTag);
        }else{
          delClass(ele, "filtered");
          existingTags.splice(numb, 1);
        }
        tag.value = existingTags.join(', ');
      }
    });

    exportset.addEventListener('click', event => {
      var set = this.getSet();
      this.exportSet(set);
    });

    deletesetbutton.addEventListener('click', event => {
      var set = this.getSet();
      this.deleteSet(set, true);
    });

    function runSave(tt){
      if( anneTextArea.value.trim().length > 0 || tag.value.trim().length > 0 ){
        if( tt.anneid.length > 0 ){
          tt.editAnnotation(t.anneid, anneTextArea, anneAuthor, tag);
        }else{
          tt.saveAnnotation(anneTextArea, anneAuthor, tag);
        }
        tag.blur();
        anneTextArea.blur();
      }
    }

    savebut.addEventListener('click', () => {
      runSave(this);
    });
    delebut.addEventListener('click', () => {
      if( this.anneid ){
        this.deleteAnnotation(this.anneid);
      }
    });

    tinykeys(window, {
      "$mod+Enter": (e) => {
        e.preventDefault();
        runSave(this);
      },
    });
    tinykeys(tag, {
      "$mod+Enter": (e) => {
        e.preventDefault();
        runSave(this);
      },
    });
    tinykeys(anneTextArea, {
      "$mod+Enter": (e) => {
        e.preventDefault();
        runSave(this);
      },
    })

    /* stops the form window closing on enter */
    tag.addEventListener("keydown", (e) => {
      const { key } = e;
      if( key === 'Enter' ){
        e.stopPropagation();
      }
    });
    tag.addEventListener("keyup", (e) => {
      const { key } = e;
      if( key === 'Enter' ){
        e.stopPropagation();
      }
    });
    tag.addEventListener("change", (e) => {
      e.stopPropagation();
      this.populateTagSelector(CleanUpTags(tag.value));
    });
    cleartags.addEventListener('click', (e) => {
      e.preventDefault();
      tag.value='';
      this.populateTagSelector(CleanUpTags(tag.value));
    });

    anneTextArea.addEventListener("keydown", (e) => {
      const { key } = e;
      if( key === 'Enter' ){
        e.stopPropagation();
      }
    });
    anneTextArea.addEventListener("keyup", (e) => {
      const { key } = e;
      if( key === 'Enter' ){
        e.stopPropagation();
      }
    });


    const sfi_import = this.querySelector('#annotationimport') as HTMLInputElement;
    if( sfi_import ){
      sfi_import.addEventListener('change', (event) => {
        if( event && event.target ){
          let fileList = (<HTMLInputElement>event.target).files;
          if ( fileList ){
            for (const file of fileList) {
              // Not supported Safari for iOS.
              const name = file.name ? file.name : 'Annotations.json';
              // Not supported Firefox, Android, Opera for Android.
              const size = file.size ? file.size : 400000;
              if( size < 400001 && name.toLowerCase().endsWith('.json')){
                const reporter = this.querySelector('span.report') as HTMLElement;
                ImportAnnotationSet(file, (setname: string, success: boolean, report: string) => {
                  reporter.innerHTML = report;
                  t.renderSets();
                  if( success ){
                    var newAnne = window.app.anns_meta.findIndex(x => x.name == setname);
                    if( newAnne > -1 && this.setMatchesPage(window.app.anns_meta[newAnne])){
                      var annes = document.querySelector("app-annotations");
                      if (annes) {
                        annes.setAttribute("latest", "loadset" + Math.random());
                        t.annotationSetLoad();
                      }
                    }
                  }
                });
              }else{
                alert('File to big at: ' +(size*1000) + 'kb.')
              }
            }
          }
        }
        sfi_import.value = '';
      });
    }
  }

  annotationSetLoad(){
    this.anneid = '';
    const anneAuthor = this.querySelector('#inputauthor') as HTMLInputElement;
    const inputSetName = this.querySelector('#inputsetname') as HTMLInputElement;
    
    anneAuthor.value = this.getAuthor();
    
    inputSetName.value = this.getSetName();
    var c_set = this.getSet();
    if( !c_set ){
      this.toggleSetDisable(true);
    }else if( c_set ){
      this.toggleSetDisable(false);
      this.setStats();
    }

    this.setSetSelect();
    this.renderSets();
  }

  getAuthor(){
    if(window.app && window.app.options && window.app.options.author_default){
      return window.app.options.author_default;
    }
    return "";
  }

  setSetSelect(){
    const setSelector = this.querySelector('#setselector') as HTMLSelectElement;
    if( setSelector ){
      for( var i = 0; i < window.app.anns_meta.length; i++){
        if( this.setMatchesPage(window.app.anns_meta[i]) ){
          var opt = document.createElement("option");
          opt.value = window.app.anns_meta[i].name;
          opt.text = window.app.anns_meta[i].name;
          setSelector.add(opt, null)
        }
      }
      if( this.annotationsetname ) setSelector.value = this.annotationsetname;
    }
  }

  setMatchesPage(desc: AnnotationDesc){
    return desc.jurisdiction == window.app.doc_meta.jurisdiction && desc.slug == window.app.doc_meta.slug && desc.version == window.app.doc_meta.version
  }

  saveSetName(inputSetName: HTMLInputElement, setName: string){
    var set = this.getSet();
    var newName = URLFriendly(setName);
    if( set && set.name != newName){
      inputSetName.value = UpdateAnnotationSetName(set.name, newName);
    }else{
      inputSetName.value = CreateAnnotationSet(setName, this.getAuthor(), null, null);
      this.setStats();
      this.toggleSetDisable(false);
    }
    this.renderSets();
  }

  populateTagSelector(tagString: string[]){
    const selectedtags = this.querySelector('#selectedtags') as HTMLDivElement;
    var tagsIn = document.querySelectorAll('#toolset .tools .tags .tagcatch') as NodeListOf<HTMLElement>;
    var output = '';
    var tagstrings: string[] = [];
    tagsIn.forEach( x => {
      tagstrings.push(x.innerHTML.replace('#',''))
    });
    var sorted = tagstrings.sort((a, b) => a.localeCompare(b));
    sorted.forEach( tag => {
      output += '<a class="tagcatch'+(tagString.indexOf(tag) > -1 ? ' filtered':'')+'" id="'+tag+'">#'+tag+'</a>';
    });
    if(selectedtags){
      selectedtags.innerHTML = output;
    } 
  }
  
  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  getSetName(){
    var set = this.getSet();
    if( set ){
      return set.name;
    }else{
      return '';
    }
  }

  getSet(){
    var set = window.app.anns.find( s => s.open );
    if( set ){
      return set;
    }else if( window.app.anns.length > 0 ){
      return window.app.anns[0];
    }else{
      return null;
    }
  }

  toggleSetDisable(disable: boolean){
    const exportset = this.querySelector('#exportset') as HTMLButtonElement;
    const deletesetbutton = this.querySelector('#deletesetbutton') as HTMLButtonElement;
    exportset.disabled = disable;
    deletesetbutton.disabled = disable;
  }

  setStats(){
    const annestats = this.querySelector('#annestats') as HTMLElement;
    var c_set = this.getSet();
    if( !c_set ){
      annestats.innerHTML = '';
    }else{
      let tags: string[] = [];
      for(var j = 0; j < c_set.annotations.length; j++){
        for( var i = 0; i < c_set.annotations[j].t.length; i++){
          if( tags.indexOf(c_set.annotations[j].t[i]) == -1 ){
            tags.push(c_set.annotations[j].t[i]);
          }
        }
      }
      annestats.innerHTML = 'Annotation count: ' + c_set.annotations.length + '<br>Unique tags: ' + tags.length;
    }
  }

  saveAnnotation(anneTextArea: HTMLTextAreaElement, anneAuthor: HTMLInputElement, tags: HTMLInputElement){
    //Do we have an annotation set.
    var setName = '';
    if( window.app.anns.length == 0 ){
      setName = CreateAnnotationSet('', anneAuthor.value, null, null);
      const inputSetName = this.querySelector('#inputsetname') as HTMLInputElement;
      inputSetName.value = setName;
      this.toggleSetDisable(false);
      this.renderSets();

      //We create an annotation description AND an annotation set
    }else{
      setName = this.getSetName();
    }
    var theSel = document.querySelector('.block.glow') as HTMLElement;
    if( theSel ){
      var a: Annotation = {
        id: '',
        bid: theSel.id,
        a: CleanUp(anneTextArea.value),
        au: anneAuthor.value,
        t: CleanUpTags(tags.value),
        u: [],
        n: [],
        r: []
      }
      var newAnneId = CreateAnnotation(a);
      const annos = document.querySelector('app-annotations') as HTMLElement;
      if( annos && newAnneId ) annos.setAttribute('latest', newAnneId+'anneaddtags|#,'+CleanUpTags(tags.value).join(','));
      if( newAnneId ){
        anneTextArea.value = '';
        this.anneid = '';
      }
      this.setStats();
    }
  }

  editAnnotation(anneid: string, anneTextArea: HTMLTextAreaElement, anneAuthor: HTMLInputElement, tags: HTMLInputElement){
    var set = window.app.anns.find( s => s.open );
    if( set ){
      var y = set.annotations.find( x => x.id == anneid);
      if( y ){
        y.a = CleanUp(anneTextArea.value);
        y.au = CleanUp(anneAuthor.value);
        y.t = CleanUpTags(tags.value);
        EditAnnotation(y);
        const annos = document.querySelector('app-annotations') as HTMLElement;
        annos.setAttribute('latest', Math.random()+'-'+y.id);
        this.setStats();
      }
    }
  }

  deleteAnnotation(anneid: string){
    var set = window.app.anns.find( s => s.open );
    if( set ){
      DeleteAnnotation(anneid);
      this.afterDelete(false);
    }
  }

  afterDelete(deleteset: boolean){
    if( deleteset ){
      const annos = document.querySelector('app-annotations') as HTMLElement;
      annos.setAttribute('latest', 'deleteset'+Math.random());
    }
    const tagcatches = document.querySelectorAll('#selectedtags .filtered') as NodeListOf<HTMLElement>
    tagcatches.forEach( x => {
      delClass(x,'filtered');
    });
    const anneTextArea = this.querySelector('#inputannotation') as HTMLTextAreaElement;
    anneTextArea.value = '';
    const tags = this.querySelector('#inputtag') as HTMLInputElement;
    tags.value = '';
    this.anneid = '';
    this.a = '';
    this.setStats();
    this.renderSets();
  }

  deleteSet(set: AnnotationSet | null, current : boolean){
    if( set ){
      var size = set.annotations.length+" annotation"+(set.annotations.length != 1 ? 's' : '');
      var kbsize = AnnotationKBSize(set.name);

      let actualDelete = confirm("You want to delete '"+set.name+"' ("+kbsize+")?\nJust pointing out it does have "+size+"...");
      if ( actualDelete ){
        if( set.annotations.length > 1 ) actualDelete = confirm("You could cancel and export that "+kbsize+" of hard work first... you sure you want to delete?");
        if ( actualDelete ){
          actualDelete = confirm("OK, final check! Delete "+set.name+" and it's "+size+" forever?");
          if ( actualDelete ){
            DeleteAnnotationSet(set.name);
            if ( current ){
              const setname = this.querySelector('#inputsetname') as HTMLInputElement;
              setname.value = '';
              this.toggleSetDisable(true);
              this.afterDelete(true);
            }else{
              this.renderSets();
            }
          }
        }
      }
    }else{
      alert('No annotation set was found here.')
    }
  }

  exportSet(set: AnnotationSet | null) {
    if( set && set.annotations && set.annotations.length > 0 ){
      var fileInput = JSON.stringify(set);
      this.download(URLFriendly(set.name)+'.json', fileInput);
    }else{
      alert( `There's no annotations for exporting! However, if you create some you can export them from here.` );
    }
  }

  renderSets(){
    const annesets = this.querySelector('#annesets') as HTMLDivElement;
    if( window.app.anns_meta.length == 0 ){
      annesets.innerHTML = `<h5 class="first">No locally created annotations.</h5>`;
    }else{
      var sets = `<h5 class="first">Your local annotation sets:</h5><dl class="setlist">`;
      for( var i = 0; i < window.app.anns_meta.length; i++){
        var base = window.app.anns_meta[i].jurisdiction+`/`+window.app.anns_meta[i].slug+`/`+window.app.anns_meta[i].version;
        var butts = `<button class="button export small" data-setname="`+window.app.anns_meta[i].name+`" title="Export this annotation set">`+this.exportsvg+`</button><button class="button delete small" data-setname="`+window.app.anns_meta[i].name+`" title="Delete this annotation set">`+this.deletesvg+`</button> <small>( `+AnnotationKBSize(window.app.anns_meta[i].name)+` )</small>`;
        if( this.setMatchesPage(window.app.anns_meta[i]) ){
          sets += `<dt>` + base + `:</dt><dd>`+window.app.anns_meta[i].name+` `+butts+`</dd>`;
        }else{
          sets += `<dt>` + base + `:</dt><dd><a href="/`+base+`/`+window.app.anns_meta[i].language+`">`+window.app.anns_meta[i].name+`</a> `+butts+`</dd>`;
        }
        
      }
      annesets.innerHTML = sets + `</dl>`;

      var t = this;
      var deleteButtons = annesets.querySelectorAll('button.delete') as NodeListOf<HTMLButtonElement>;
      deleteButtons.forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          var set = LoadAnne(item.dataset.setname || '');
          if( set ){
            this.deleteSet(set, item.dataset.setname == this.getSetName());
          }else{
            console.log(`ok that's weird`);
          }
        });
      });

      var exportButtons = annesets.querySelectorAll('button.export') as NodeListOf<HTMLButtonElement>;
      exportButtons.forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          var set = LoadAnne(item.dataset.setname || '');
          if( set ){
            this.exportSet(set);
          }else{
            console.log(`ok that's weird`);
          }
        });
      });
    }
  }
}