import { SwapCurrent } from './navigator';
import { addClass, delClass, hasClass, CleanUpBack } from "./utils";

export default class StudyBlock extends HTMLElement {

  activetab: string;
  blockid: string;
  editing: boolean;
  
  static get observedAttributes() {
    return ["blockid", "tabid", "editing"];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if( name == 'blockid'){
      this.blockid = newValue;
      this.alterRender();
    }else if( name == 'tabid' ){
      var tab_tag = document.querySelector('#'+newValue) as HTMLElement;
      if( tab_tag) this.switchTab(tab_tag);
    }else if( name == 'editing' ){
      this.editing = newValue == "true"
      this.alterRender();
    }
  }


  connectedCallback() {
    this.activetab = 'annotations';
    this.editing = true;
    this.render();
    this.alterRender();
  }

  alterRender(){
    /* save ourselves a redraw for reasons */
    var title = this.querySelector('h4>span') as HTMLElement;
    if( title ) title.innerHTML = this.blockid;
    var tabset = this.querySelector('.tabset') as HTMLElement;
    var noblock = this.querySelector('#needablock') as HTMLElement;
    var tag = document.querySelector('#tag') as HTMLElement;
    if( !this.editing ){
      if(tag) addClass(tag, 'hide');
    }else{
      if(tag) delClass(tag, 'hide');
    }
    if( tabset && noblock ){
      if( this.blockid ){
        delClass(tabset, 'hidden');
        addClass(noblock, 'hidden');
      }else{
        delClass(noblock, 'hidden');
        addClass(tabset, 'hidden');
      }
    }
  }

  render = function(){
    const urlParams = new URLSearchParams(window.location.search);
    var output = `<a href="#" class="poptag" id="tag" title="Toggle annotation/tagging interface" aria-label="Toggle annotation/tagging interface">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="quill" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-feather"><path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path><line x1="16" y1="8" x2="2" y2="22"></line><line x1="17.5" y1="15" x2="9" y2="15"></line></svg>
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="open" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-up"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="close" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-down"><polyline points="7 13 12 18 17 13"></polyline><polyline points="7 6 12 11 17 6"></polyline></svg>
      </a>
      <div class="scroller">
        <div class="studypanel">
          <h4 class="pre" for="annotation">Block ID: #<span>`+this.blockid+`</span></h4>
          <div class="tabset">
            <div class="tabs" role="tablist">`;
    output += `<div class="tab" tab-panel="annotations" tab-active="`+(this.activetab=='annotations'?'true':'false')+`" role="tab" tabindex="0" aria-disabled="false" aria-selected="`+(this.activetab=='annotations'?'true':'false')+`" id="tab_annotate">Annotate</div>`;
    output += `<div class="tab" tab-panel="tags" tabindex="0" tab-active="`+(this.activetab=='tags'?'true':'false')+`" role="tab" aria-disabled="false" aria-selected="`+(this.activetab=='tags'?'true':'false')+`" id="tab_tag">Tag</div>`;
    //output += `<div class="tab set" tabindex="0" tab-panel="urls" tab-active="`+(this.activetab=='urls'?'true':'false')+`" role="tab" aria-disabled="false" aria-selected="`+(this.activetab=='urls'?'true':'false')+`" id="tab_urls">URLs</div>`;
    //output += `<div class="tab" tab-panel="notable" tabindex="0" tab-active="`+(this.activetab=='notable'?'true':'false')+`" role="tab" aria-disabled="false" aria-selected="`+(this.activetab=='notable'?'true':'false')+`" id="tab_notable">Notable</div>`;
    //output += `<div class="tab" tabindex="0" tab-panel="related" tab-active="`+(this.activetab=='related'?'true':'false')+`" role="tab" aria-disabled="false" aria-selected="`+(this.activetab=='related'?'true':'false')+`" id="tab_related">Related</div>`;
    output += `<div class="tab" tab-panel="save" tabindex="0" tab-active="`+(this.activetab=='save'?'true':'false')+`" role="tab" aria-disabled="false" aria-selected="`+(this.activetab=='save'?'true':'false')+`" id="tab_set">Set</div>`;
    output += `<div class="tab" tab-panel="sets" tabindex="0" tab-active="`+(this.activetab=='sets'?'true':'false')+`" role="tab" aria-disabled="false" aria-selected="`+(this.activetab=='sets'?'true':'false')+`" id="tab_sets">Sets</div>`;
    output += `<div class="tab right" tab-panel="help" tabindex="0" tab-active="`+(this.activetab=='help'?'true':'false')+`" role="tab" aria-disabled="false" aria-selected="`+(this.activetab=='help'?'true':'false')+`" id="tab_guide">Guide <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg></div>
              <div class="tab-group__indicator"></div>
            </div>
            <app-studyform anneid=""></app-studyform>
          </div><p id="needablock">Select a block above to annotate</p>
        </div>
      </div>`;

    this.innerHTML = output
    //}

    
    var studyForm = this.querySelector('app-studyform') as HTMLElement;
    const tag = this.querySelector('#tag') as HTMLElement;
    const t = this;
    if(tag){
      tag.addEventListener('click', (e) => {
        e.preventDefault();
        if( !hasClass(t, 'open') ){
          if(!t.blockid){
            var glower = document.querySelector('.block.glow');
            if( glower && glower.id ){
              t.blockid = glower.id;
              t.alterRender();
            }
          }
          addClass(t, 'open');
          studyForm.setAttribute('latest', Math.random().toString());
          PickFocus();
        }else{
          delClass(t, 'open');
          /* close any hover state annotations */
          var hshover = document.querySelector('.hshover') as HTMLElement;
          if( hshover ) delClass( hshover, 'hshover');
        }
      });
    }

    var tabs = this.querySelectorAll('.tab') as NodeListOf<HTMLElement>;
    if(tabs){
      tabs.forEach(function (tab, currentIndex, listObj) {
        tab.addEventListener("click", (event) => {
          t.switchTab(tab);
        });
      });
    }
    var firsttab = this.querySelector('.tab') as HTMLElement;
    if( firsttab ){
      firsttab.click();
      this.repositionIndicator(firsttab);
    }
  }

  switchTab = function(tab: HTMLElement){
    var t = this;
    var tabs = t.querySelectorAll('.tab') as NodeListOf<HTMLElement>;
    var buttons = document.querySelector('#tabsave') as HTMLElement;
    var tabpanels = t.querySelectorAll('.tab-panel') as NodeListOf<HTMLElement>;
    tabs.forEach(function (tab2, currentIndex, listObj) {
      if( tab2.getAttribute('tab-panel') == tab.getAttribute('tab-panel')){
        tab2.setAttribute('tab-active', 'true');
        tab2.setAttribute('aria-selected', 'true');
        addClass(tab2, 'active');
      }else{
        tab2.setAttribute('tab-active', 'false');
        tab2.setAttribute('aria-selected', 'false');
        delClass(tab2, 'active');
      }
    });
    if( tab.getAttribute('tab-panel') == 'help' || tab.getAttribute('tab-panel') == 'sets' || tab.getAttribute('tab-panel') == 'save'){
      addClass(buttons, 'reallyhide');
      addClass(t, 'big');
    }else{
      delClass(buttons, 'reallyhide');
      delClass(t, 'big');
    }
    if( tabpanels ){
      tabpanels.forEach(function (tabpanel, currentIndex, listObj) {
        if( tabpanel.getAttribute('name') == tab.getAttribute('tab-panel')){
          tabpanel.setAttribute('aria-hidden', 'false');
          addClass(tabpanel, 'visible');
        }else{
          tabpanel.setAttribute('aria-hidden', 'true');
          delClass(tabpanel, 'visible');
        } 
      });
      t.repositionIndicator(tab);
    }
  }

  repositionIndicator = function(currentTab : HTMLElement) {
    const allTabs = [...this.querySelectorAll('.tab') as NodeListOf<HTMLElement>];
    const indicator = this.querySelector("div.tab-group__indicator") as HTMLElement;
  
    if (!currentTab) {
      return;
    }
    const width = currentTab.clientWidth;
  
    const precedingTabs = allTabs.slice(0, allTabs.indexOf(currentTab));
    const offset = precedingTabs.reduce(
      (previous, current) => ({
        left: previous.left + current.clientWidth,
        top: previous.top + current.clientHeight
      }),
      { left: 0, top: 0 }
    );
    
    indicator.style.width = `${width}px`;
    indicator.style.height = 'auto';
    if( hasClass(currentTab, 'right') ){
      var makeitright = (currentTab.parentElement?.clientWidth || 0) - width;
      indicator.style.translate = `${makeitright}px`;
    }else{
      indicator.style.translate = `${offset.left}px`;
    }
  }
}

export function PickFocus(){
  const author = document.querySelector('#inputauthor') as HTMLInputElement;
  if( author && author.value.trim().length == 0 ){
    author.focus();
  }else{
    const inputtag = document.querySelector('#inputtag') as HTMLInputElement;
    const textarea = document.querySelector('#inputannotation') as HTMLTextAreaElement;
    var studyblock = document.querySelector('app-studyblock') as HTMLElement;
    if( inputtag && textarea && inputtag.value.trim().length > 0 && textarea.value.trim().length == 0){
      if(studyblock) studyblock.setAttribute('tabid','tab_tag');
      inputtag.focus();
    }else{
      if(studyblock) studyblock.setAttribute('tabid','tab_annotate');
      textarea.focus();
    }
  }
}

export function StudyUpdate(id: string){
  const appStudyform = document.querySelector('app-studyblock') as HTMLElement;
  if(appStudyform){
    appStudyform.setAttribute("blockid", id);
  }
}

export function StudyToggle(anneId: string){
  var studyBlock = document.querySelector('app-studyblock') as HTMLElement;
  var studyForm = document.querySelector('app-studyform') as HTMLElement;

  if( studyBlock && studyForm){
    const textarea = document.querySelector('#inputannotation') as HTMLTextAreaElement;
    var openAnnes = window.app.anns.find(x => x.open);
    var id = anneId.replace('anne-','');
    if( id && openAnnes ){
      studyForm.setAttribute("anneid", id);
      var a = openAnnes.annotations.find(x => x.id == id);
      if( a ){
        const annee = document.querySelector('#'+anneId) as HTMLTextAreaElement;
        const annesel = document.querySelectorAll('.annesel') as NodeListOf<HTMLElement>;
        annesel.forEach(function (an, currentIndex, listObj) {
          delClass(an, 'annesel');
        });
        addClass(annee, 'annesel');
        const block = document.querySelector('#'+a.bid) as HTMLTextAreaElement;
        textarea.value = CleanUpBack(a.a);
        SwapCurrent(block, true);
      }
      if( !hasClass(studyBlock, 'open') ) addClass(studyBlock, 'open');
      PickFocus();
    }else{
      if( !hasClass(studyBlock, 'open') ){
        addClass(studyBlock, 'open');
        PickFocus();
      }else{
        delClass(studyBlock, 'open');
        textarea.blur();
      }
    }
  }
}
