import { AnnotationSet, AnnotationCollection, Tag } from '../types';
import { delClass, arrayHasItem, filterLoveLetter } from "../utils";


export function Sorter(activeset: AnnotationSet, tag: Tag){
  var collector: AnnotationCollection[] = [];
  tag.tags = [];
  if( activeset ){
    for(var i = 0; i < activeset.annotations.length; i++){
      for(var j = 0; j < activeset.annotations[i].t.length; j++){
        if( tag.tags.indexOf(activeset.annotations[i].t[j]) == -1 ) tag.tags.push(activeset.annotations[i].t[j]);
      }
      var stId = shortenTDTHtoTR(activeset.annotations[i].bid);
      if( collector.findIndex( x => x.bid == stId ) == -1 && stId ){
        var bid = document.querySelector('#'+stId) as HTMLElement;
        if( bid){
          var index = bid.dataset.index ? +bid.dataset.index : 100000;
          collector.push({
            bid: stId,
            index: index,
            yoffset: 0,
            filterIn: true,
            items: []
          });
        }
      }
      collector.find( x => x.bid == stId )?.items.unshift(activeset.annotations[i]);
    }
    collector.sort(function(a, b) { return a.index - b.index });
    var clearLast = document.querySelectorAll('.tagin') as NodeListOf<HTMLElement>;
    clearLast.forEach((item) => {
      delClass(item, 'tagin');
    });

    for( var i = 0; i < collector.length; i++ ){
      collector[i].items.sort(function(a, b) { return +b.id - +a.id });
      if( tag.filter && tag.filter.length > 0 ){
        var hasTagInItems = false;
        for( var j = 0; j < collector[i].items.length; j++){
          if ( arrayHasItem(collector[i].items[j].t, tag.filter) ){
            hasTagInItems = true;
            break;
          }
        }
        collector[i].filterIn = hasTagInItems;
      }
    }
    collector = collector.filter( x => x.filterIn );
    for( var i = 0; i < collector.length; i++ ){
      if( collector[i].filterIn ){
        var thing = (document.querySelector('#'+collector[i].bid) as HTMLElement);
        filterLoveLetter(thing, 'tagin');
      }
    }
  } 
  return collector;
}

function shortenTDTHtoTR( bid: string ){
  if( bid.lastIndexOf('-tb') > -1){
    if( bid.lastIndexOf('-td' ) > -1 ){
      return bid.substring(0, bid.lastIndexOf('-td' ));
    }else if( bid.lastIndexOf('-th' ) > -1 ){
      return bid.substring(0, bid.lastIndexOf('-th' ));
    }
  }
  return bid;
}

export function HeightCalculator( collector: AnnotationCollection[], loveletter_top: number, hide: boolean){
  var lltop = Math.round(loveletter_top);
  for( var i = 0; i < collector.length; i++ ){
    if( hide ){
      collector[i].yoffset = -1000;
    }else{
      if( collector[i].filterIn ){
        var bid = document.querySelector('#'+collector[i].bid) as HTMLElement;
        if( bid.checkVisibility() ){
          collector[i].yoffset = Math.round(bid.getBoundingClientRect().top)-lltop;
        }else{
          collector[i].yoffset = -999;
        }
      }
    }
  }
  return collector;
}