import { SwapCurrent } from './navigator';
import { hasClass } from './utils';

export function CharOnLoad(hash: string | undefined){
  if( hash ){
    var chardelim = '-char';
    var h = hash.replace('#','');
    var x = document.getElementById(h);
    if( !x && h.indexOf(chardelim) > -1){ /* the element doesn't exist but there's characters */
      var cha = h.split(chardelim);
      var x = document.getElementById(cha[0]);
      if( x && cha.length > 1){
        SwapCurrent(x, false);
        var range = cha[1].split('-');
        var start_index: number = +range[0];
        var end_index: number = +range[1];
        nodeWalker(x, x.childNodes, 0, start_index, end_index);
      }
    }
  }
}

export function CharOnSelect(sel: Selection | null){
  if( sel ){
    /*
    var startNode = sel.anchorNode;
    var endNode = sel.focusNode;
    var start = sel.anchorOffset;
    var end = sel.focusOffset;
    if( startNode && endNode ){
      var length = nodeWalkerSel(startNode, startNode, endNode, start, end, 0);
      console.log(start+1, length+1);
    }*/
  }
}

function nodeWalkerBack(firstElement: Node, length: number){
  //if(hasClass(firstElement,'block')){

  //}
}

function nodeWalkerSel(firstElement: Node, currentElement: Node, lastELement: Node, start: number, end: number, length: number){
  var position = currentElement.compareDocumentPosition(lastELement);
  if (position & Node.DOCUMENT_POSITION_FOLLOWING) {
    console.log('firstElement comes before lastELement'); 
    if( currentElement.nodeType == 3 && currentElement.textContent){
      console.log(currentElement.textContent, currentElement.textContent.length);
      length += currentElement.textContent.length-start;
      start = 0;
    }
    if( currentElement.childNodes && currentElement.childNodes.length > 0){
      console.log('children', currentElement.childNodes)
      length = nodeWalkerSel(firstElement, currentElement.childNodes[0], lastELement, start, end, length);
    }else if( currentElement.nextSibling ){
      console.log('next', currentElement.nextSibling)
      length = nodeWalkerSel(firstElement, currentElement.nextSibling, lastELement, start, end, length);
    }else if(currentElement.parentElement?.nextSibling){
      length = nodeWalkerSel(firstElement, currentElement.parentElement?.nextSibling, lastELement, start, end, length);
      console.log('what now1?', currentElement);
    }else if(currentElement.parentElement?.parentElement?.nextSibling){
      length = nodeWalkerSel(firstElement, currentElement.parentElement?.parentElement?.nextSibling, lastELement, start, end, length);
      console.log('what now2?', currentElement);
    }

  } else if (position & Node.DOCUMENT_POSITION_PRECEDING) { 
      console.log('firstElement comes after lastELement'); 
  } else { 
    console.log('we reached last element', length, end); 
    if( currentElement.nodeType == 3 && currentElement.textContent){
      console.log(currentElement.textContent, currentElement.textContent.length);
      length += end;
      return length;
    }
  }
  return length;
}

function nodeWalker(lastElement: Node, ele: NodeListOf<ChildNode>, childIndex: number, start: number, end: number){
  if( ele[childIndex].nodeType == 3 && ele[childIndex].textContent){ // text node i.e. #text
    var txt = ele[childIndex].textContent;
    if( txt ){
      var l = txt.length;
      if( start > l ){
        start = start - l;
        end = end - l;
        //console.log('Skip:', l, start, end, txt);
      }else if( end > 0 ){
        if( end > l ){
          end = end - l;
          if( start > 1 ){
            var txt = ele[childIndex].textContent;
            if( txt && txt != '\n'){
              var unmarkNode = document.createTextNode(txt.substring(0, start-1));
              var mark = document.createElement('mark');
              mark.appendChild(document.createTextNode(txt.substring(start-1)));
              ele[childIndex].replaceWith(unmarkNode, mark);
              //console.log('end > l & start > 1', l, start, end);
            }
            start = 0;
          }else {
            //console.log('end > l & start <= 1', l, start, end, txt);
            //we wrap
            if( txt && txt != '\n'){
              let mark = document.createElement('mark');
              mark.appendChild(document.createTextNode(txt));
              ele[childIndex].replaceWith(mark);
            }
          }
        }else{
          var txt = ele[childIndex].textContent;
          if( start > 1 ){
            if( txt && txt != '\n'){
              var unmark = txt.substring(0, start-1);
              var marked = txt.substring(start-1, end);
              var remainunmarked = txt.substring(end);
              var unmarkNode = document.createTextNode(unmark);
              var mark = document.createElement('mark');
              mark.appendChild(document.createTextNode(marked))
              var remainunmarkedNode = document.createTextNode(remainunmarked);
              ele[childIndex].replaceWith(unmarkNode, mark, remainunmarkedNode);
              //console.log('end <= l & start > 1', l, start, end, unmark, marked, remainunmarked);
            }
            start = 0;
            end = 0;
          }else{
            if( txt && txt != '\n'){
              var mark = document.createElement('mark');
              mark.appendChild(document.createTextNode(txt.substring(0, end)))
              var remainunmarkedNode = document.createTextNode(txt.substring(end));
              ele[childIndex].replaceWith(mark, remainunmarkedNode);
              //console.log('end <= l & start <= 1', l, start, end);
            }
            end = 0;
          }
          end = 0;
        }
      }else{
        //console.log('Breaking as end = 0');
      }
    }
  }//else if( ele.nodeType == 1 ){ // element like p, dfn
  if( end > 0 ){
    if( childIndex < (ele.length-1) ){ //this is not the last child in the set
      if ( ele[childIndex].nodeType == 3 || ele[childIndex].nodeName.toLowerCase() == 'mark'){
        nodeWalker(lastElement, ele, childIndex+1, start, end);
      }else if( ele[childIndex].nodeType == 1 ){
        if( ele[childIndex].childNodes.length > 0 ){
          end = nodeWalker(ele[childIndex], ele[childIndex].childNodes, 0, start, end);
        }
        nodeWalker(lastElement, ele, childIndex+1, start, end);
      }else{
        console.log('This was... unexpected');
      }
    } else { //this is the last child in the set
      if( ele[childIndex].nodeType == 1 && ele[childIndex].nodeName.toLowerCase() != 'mark'){
        if( ele[childIndex].childNodes.length > 0){
          nodeWalker(ele[childIndex], ele[childIndex].childNodes, 0, start, end);
        }else{
          //console.log('This was... woah x2!');
        }
      }else{
        if( lastElement.childNodes.length == 1 && ele[childIndex].nodeName.toLowerCase() == 'mark' ){
          //console.log('Gone down a one way, used some chars so we return the newly calculated end');
          return end;
        }else{
          var nextelement = getNextElement(lastElement);
          if( nextelement ){
            nodeWalker(nextelement, nextelement.childNodes, 0, start, end);
          }else{
            //console.log('This was maybe unexpected');
          }
        }
      }
    }
  }
  return 0;
}

function getNextElement(lastElement: Node){
  function notMark(ele){
    if( ele && ele.nodeName.toLowerCase() != 'mark'){
      return true;
    }else{
      return false;
    }
  }
  var nextelement = notMark(lastElement.nextSibling) ? lastElement.nextSibling : null;
  //console.log( lastElement )
  //if( !nextelement ) {
  //  nextelement = lastElement && lastElement.parentNode && notMark(lastElement.parentNode.nextSibling) ? lastElement.parentNode.nextSibling : null;
  //}
  if( nextelement && nextelement.nodeType == 3 && nextelement.nodeValue == '\n' ){
    return getNextElement(nextelement);
  }else{
    return nextelement;
  }
}


export function HighlightText(ele: HTMLElement, start, end){
  if( ele ){

  }
}