
import VersionPicker from './versionpicker';
import Provision from './provision';
import Render from './render';
import StudyBlock from './studyblock';
import StudyForm from './studyform';
import { Navigator } from './navigator';
import { addClass } from './utils';
import DarkMode from './darkmode';
import { App, DocMeta, AnnotationDesc, OptionsT  } from './types';
import Annotations from './annotations';
import { CharOnLoad } from './charselect';
import { AnnotationsExist } from './annotations/utils';
import { LoadAnnes } from './storage';
import Account from './account';
import SideBar from './sidebar';
import Referencer from './referencer';
/*
import { Minimap } from './minimap/minimap';
import { ViewContext } from './minimap/viewcontext';
import { EditorConfiguration } from './minimap/editorconfiguration';
import { IColorTheme } from './minimap/themeservice';*/

declare global {
  interface Window { app: App; }
}

if( window ){
  window.customElements.define('app-version', VersionPicker);
  window.customElements.define('app-provision', Provision);
  window.customElements.define('app-account', Account);
  window.customElements.define('app-darkmode', DarkMode);
  window.customElements.define('app-render', Render);
  window.customElements.define('app-studyblock', StudyBlock);
  window.customElements.define('app-studyform', StudyForm);
  window.customElements.define('app-annotations', Annotations);
  window.customElements.define('app-referencer', Referencer)
  window.customElements.define('app-sidebar', SideBar)
  var localStoreAnnes = (localStorage.getItem("anns_meta") || "");
  var localStoreOptions = (localStorage.getItem("anns_options") || "");
  var a: AnnotationDesc[] = localStoreAnnes.length == 0 ? [] : JSON.parse(localStoreAnnes);
  var op: OptionsT = localStoreOptions ? JSON.parse(localStoreOptions) : { "author_default": ""};
  var checkop = {
    author_default: op.author_default || ''
  } as OptionsT;
  var doc_meta = document.querySelector('meta[name="application-ref"]') as HTMLMetaElement;
  if( doc_meta ){
    var docm = doc_meta.dataset as DocMeta;
    if( doc_meta ){
      var annes = LoadAnnes(a, docm);
      if( !window.app ){
        window.app = {
          anns_meta: a,
          doc_meta: docm,
          options: checkop,
          anns: annes,
          versions: [],
          changelogs: [],
          navigation: [],
          refcount: 0,
          has_annotations: AnnotationsExist(annes)
        };
      }else{
        window.app.anns_meta = a;
        window.app.doc_meta = docm;
        window.app.options = checkop;
        window.app.anns = annes;
        window.app.has_annotations = AnnotationsExist(annes);
      }
    }
  }

  var timeout;
  function redraw(){
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      var annes = document.querySelector('app-annotations') as HTMLElement;
      var appco = document.querySelector('app-compare') as HTMLElement;
      if( annes ) {
        annes.setAttribute('redraw', 'noscroll'+Math.random());
      }else if( appco ){
        appco.setAttribute('redraw', 'noscroll'+Math.random());
      }
    }, 400);
  }

  window.addEventListener('resize', function(event) {
    redraw();
  }, true);

  document.addEventListener("DOMContentLoaded", function() {
    CharOnLoad( window.location.hash );
    var art = document.querySelector('article.instrument') as HTMLElement;
    if( art ) addClass(art, 'loading');
    Navigator();
    if (screen && screen.orientation !== null) {
      try {
        window.screen.orientation.onchange = () => {
          redraw()
        };
      }
      catch (e) {  }
    }
  });
}

export {};
