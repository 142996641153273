import { AnnotationSet } from '../types';
import { addClass, delClass } from "../utils";

export function ActiveAnne(annes: AnnotationSet[] | null){
  if( annes ){
    var anno = annes.find(a => a.open);
    if( anno ) return anno;
    if ( annes.length > 0 ) return annes[0];
  }
  return null;
}

/* initial check utilised on page load to check if annotations exist */
export function AnnotationsExist(annes: AnnotationSet[]){
  const urlParams = new URLSearchParams(window.location.search);
  var preanne = urlParams.get('annotation');
  if( preanne ) return true;
  var x = ActiveAnne(annes);
  if( x ){
    return true;
  }
  return false;
}

export function removeFilteredClass(item : HTMLElement){
  delClass(item, 'filtered');
  var toolsetother = document.querySelector('#toolset a[data-filter="'+item.dataset.filter+'"]') as HTMLElement;
  delClass(toolsetother, 'filtered');
  var mobileTag = document.querySelector('#mobile-anne a[data-filter="'+item.dataset.filter+'"]') as HTMLElement;
  if( mobileTag ){
    delClass(mobileTag, 'filtered');
  }
}

export function addFilteredClass(item : HTMLElement){
  addClass(item, 'filtered');
  if( item.dataset.filter ) highlightTags(item.dataset.filter);
}

export function highlightTags(tag : string){
  var toolsetother = document.querySelector('#toolset a[data-filter="'+tag+'"]') as HTMLElement;
  addClass(toolsetother, 'filtered');
  var mobileTag = document.querySelector('#mobile-anne a[data-filter="'+tag+'"]') as HTMLElement;
  if( mobileTag ) addClass(mobileTag, 'filtered');
}
