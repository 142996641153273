import copy from 'copy-to-clipboard';
import { tinykeys } from 'tinykeys';
import { SwapCurrent } from './navigator';
import { addClass, delClass } from './utils';

export default class Referencer extends HTMLElement {

  blockid: string;
  
  static get observedAttributes() {
    return ["blockid"];
  }

  connectedCallback() {
    this.blockid = '';
    this.innerHTML = this.render();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if( name == 'blockid'){
      this.blockid = newValue;
      this.innerHTML = this.render();
      this.events();
    }
  }

  render(){
    if( this.blockid && this.blockid.length > 0 ){
      var copysvg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>`;
      return `<div class="contain">
        <div class="wider">
          <div class="controls">
            <a href="#" class="buttonink left" id="copyid" title="Copy Reference (Ctrl+Alt+C)">`+copysvg+`#Ref</a><a href="#" class="buttonink center" id="copyuri" title="Copy URI (Ctrl+C)">`+copysvg+`URL</a><a href="#" id="clearblock" class="buttonink right">Clear</a>
          </div>
          <div class="blockid">#`+this.blockid+`</div>
        </div>
      </div>`;
    }else{
      return '';
    }
  }

  events(){
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    var t = this;
    var blockid = this.querySelector('.blockid') as HTMLElement;
    var copyid = this.querySelector('#copyid') as HTMLElement;
    var copyuri = this.querySelector('#copyuri') as HTMLElement;
    var clear = this.querySelector('#clearblock');

    var styles = getComputedStyle(blockid);
    var alttheme = styles.getPropertyValue('--alt-theme');
    if( alttheme && metaThemeColor ){
      metaThemeColor.setAttribute('content', alttheme);
    }

    function bob(ta: HTMLElement){
      if( ta ){
        addClass(ta, 'bob');
        setTimeout(() => {
          delClass(ta, 'bob');
        }, 200);
      }
    }

    if( copyid ){
      copyid.addEventListener('click', (e) => {
        e.preventDefault();
        copy('#'+this.blockid);
        bob(e.currentTarget as HTMLElement);
        return false;
      });
    }

    if( copyuri ){
      copyuri.addEventListener('click', (e) => {
        e.preventDefault();
        copy(window.location.origin+window.location.pathname+'#'+this.blockid);
        bob(e.currentTarget as HTMLElement);
        return false;
      });
    }

    if( clear ){
      clear.addEventListener('click', (e) => {
        e.preventDefault();
        t.blockid = '';
        t.innerHTML = this.render();
        SwapCurrent(null, true);
        var body = document.querySelector('body');
        if(body){
          var styles2 = getComputedStyle(body);
          var theme = styles2.getPropertyValue('--theme');
          if( theme && metaThemeColor ){
            metaThemeColor.setAttribute('content', theme);
          }
        }
        return false;
      });
    }


    tinykeys(window, {
      "Control+KeyC": event => {
        var selectedText = window.getSelection();
        if(this.blockid && (!selectedText || selectedText.toString().length == 0)){
          copy(window.location.origin+window.location.pathname+'#'+this.blockid);
          bob(copyuri);
        }
      }
    });

    tinykeys(window, {
      "Control+Alt+KeyC": event => {
        if(this.blockid){
          copy('#'+this.blockid);
          bob(copyid);
        }
      }
    });
  }
}