export default class VersionPicker extends HTMLElement {

  connectedCallback() {
    var url = this.getAttribute('url');

    this.innerHTML = `<div class="redirecting">
        <div id="redirectlatest">
          <dl>
            <section>
              <dt><a href="`+url+`" id="latestversion">`+this.getAttribute('latest')+`</a></dt>
              <dd><a href="`+url+`" id="latestversion">`+this.getAttribute('date')+`</a></dd>
            </section>
          </dl>
          <p class="notice">Redirecting to latest version:</p>
        </div>
        <button class="action redirector" id="redirector" variant="warning" size="medium">
          Cancel Redirect
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-octagon"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
        </button>  
      </div>`;

    var cancelled = false;
    const redirector = document.querySelector('#redirector') as HTMLButtonElement;
    const latestVersion = document.querySelector('#latestversion');
    const redirectlatest = document.querySelector('#redirectlatest');
    if( redirector && latestVersion){
      setTimeout(() => {
        if( latestVersion != null ){
          var z = latestVersion.getAttribute("href");
          if( z != null ){
            window.location.href = z;
          }
        }
      }, 55000);
      
      redirector.addEventListener("click", function (e) {
        const highestId = window.setTimeout(() => {
          for (let i = highestId; i >= 0; i--) {
            window.clearTimeout(i);
          }
        }, 10);
        if ( redirectlatest && !cancelled){
          redirectlatest.remove();
          this.innerHTML = 'Go to latest version <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>';
          redirector.setAttribute('class','redirector');
          if( url ) redirector.setAttribute('href', url);
          cancelled = true;
        } else {
          var z = redirector.getAttribute("href");
          if( z ) window.location.href = z;
        }
      });
    }
  }
}
