
export const URLFriendly = function(input: string){
  return encodeURIComponent((input).replace(/[^A-Za-z0-9_-]+/gi, '-'));
}

export const alterURL = function(newURL: string){
  //get anchor and add below
  var anchor = (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  var qs = newURL ? '?'+newURL : '?';
  if( anchor ){
    window.history.pushState(null, '', qs + '#' + anchor);
  }else{
    window.history.pushState(null, '', qs);
  }
}

export function arrayHasItem(a1, a2) {
  return a2.some(function (v) {
      return a1.indexOf(v) >= 0;
  });
}

export function hasClass(e : HTMLElement, className : string) {
  if (e.classList) {
    return e.classList.contains(className);
  }else{
    return !!e.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  }
}

export function addClass(ele : HTMLElement, className : string){
  if (ele.classList){
    ele.classList.add(className)
  }else if (!hasClass(ele, className)){
    ele.className += " " + className;
  }
}

export function delClass(ele : HTMLElement, className : string){
  if (ele.classList){
    ele.classList.remove(className)
  }else if (hasClass(ele, className)){
    var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

export function CleanUpBack(stIn: string){
  var output = String(stIn).replace(/&amp;/g,'&');
  output = output.replace(/&lt;/g,'<');
  output = output.replace(/&gt;/g,'>');
  output = output.replace(/&quot;/g,'"');
  output = output.replace(/&#39;/g,"'");
  output = output.replace(/&#x2F;/g,"/");
  output = output.replace(/&#x60;/g,"`");
  output = output.replace(/&#x3D;/g,"=");
  return output;
}

export function CleanUpBackArray(stIn: string[]){
  return CleanUpBack(stIn.join(", "));
}

export function CleanUp(stIn: string){
  return String(stIn).replace(/[&<>"'`=\/]/g, function fromEntityMap (s) {
    return entityMap[s];
  });
}

export function CleanUpTags(stIn :string){
  var x = CleanUp(stIn).split(',').map(function(t) {
    return t.trim().replace(/[ #%&'<>.?@//:;=+"]/g,'-');
  });
  if( x.length == 1 && x[0] == ''){
    return [];
  }else{
    x = x.filter(x => x != ''); // removes empty entries
    return [...new Set(x)];// strips duplicates
  }
}

export function RunUp( e: HTMLElement ){
  if( e.classList.contains('block') ){
    return e as HTMLElement;
  }else if( e.parentElement ){
    return RunUp( e.parentElement );
  }else{
    //console.log( 'block not found');
  }
}

export function filterLoveLetter(thing: HTMLElement, shower: string){
  var isLL = false;
  if( thing.nodeName.toLowerCase() == 'div' ){
    var children = thing.querySelectorAll('.block') as NodeListOf<HTMLElement>;
    children.forEach((ele) => {
      addClass(ele, shower);
    });
  }

  do {
    isLL = hasClass(thing, 'll');
    if( !isLL ){
      addClass(thing, shower);
      var heading = thing.querySelector(":scope > h1.block,:scope > h2.block,:scope > h3.block,:scope > h4.block,:scope > h5.block,:scope > h6.block") as HTMLElement;
      var tables = thing.querySelectorAll(':scope > td.block') as NodeListOf<HTMLElement>;
      if(heading)  addClass(heading, shower);
      if( thing.nodeName.toLowerCase() == 'table' ){
        var tableHeading = thing.previousElementSibling as HTMLElement;
        if( tableHeading && tableHeading.nodeName.toLowerCase().startsWith('h') ){
          addClass(tableHeading, shower);
        }
      }
      if(tables){
        tables.forEach((ele) => {
          addClass(ele, shower);
        });
      }
      
      thing = thing.parentElement as HTMLElement;
    }
  } while (!isLL);
}

var entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;'
};
