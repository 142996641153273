import { addClass, delClass, hasClass } from "./utils";

export default class SideBar extends HTMLElement {

  connectedCallback() {
    this.innerHTML = this.render();
    this.events();
  }

  render() {
    var nav = window.app.navigation;
    var output = '<ul>';
    var n = nav.filter(x => x.level < 1);
    for (var i = 0; i < n.length; i++) {
      if (i > 0 && n[i - 1].level == 2 && n[i].level != 2) {
        output += `</ul></li>`
      }
      var title = n[i].title ? n[i].title : n[i].id;
      var subTitle = '';
      if (title.indexOf(' | ') > -1) {
        var temp = title.split(' | ');
        title = temp[0];
        subTitle = temp[1];
      }
      if( n[i].level == -1 ){
        output += `<li class="l` + n[i].level + `"><label>` + title + `</label><hr></li>`;
      }else{
        output += `<li class="l` + n[i].level + `"><a href="#` + n[i].id + `" class="navlink">` + title + ` <br><span>` + subTitle + `</span></a>`;
      }
      if (n.length > i + 1 && n[i].level == 1 && n[i + 1].level == 2) {
        output += `<ul>`;
      } else {
        output += `</li>`;
        ;
      }
    }
    output += '</ul>';
    return `<div class="sidebar" id="sidebar"><div class="scroll">
      <a href="#" class="poptag" id="map" title="Toggle sidebar" aria-label="Toggle sidebar">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="map" wwidth="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map"><polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon><line x1="8" y1="2" x2="8" y2="18"></line><line x1="16" y1="6" x2="16" y2="22"></line></svg>
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="close" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-right"><polyline points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
      </a>
      <p class="blockcount">Ref Count: <span>` + window.app.refcount + `</span></p>` + output + `</div></div>`;
  }

  events(){
    const body = document.querySelector('body') as HTMLElement;
    const map = this.querySelector('#map') as HTMLElement;
    const sidebar = this.querySelector('#sidebar') as HTMLElement;
    
    map.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      if( hasClass(sidebar,'slideout') ){
        delClass(sidebar,'slideout');
      }else{
        addClass(sidebar,'slideout');
      }
    });

    sidebar.addEventListener('click', e => {
      const { target } = e;
      if ( target && target instanceof HTMLElement) { 
        var tagName = target.nodeName.toLowerCase();
        if( tagName == 'div' ){
          if( !hasClass(sidebar,'slideout') ){
            addClass(sidebar,'slideout');
          }
        }else{
          e.stopPropagation();
        }
      }
    });
    
  }

}