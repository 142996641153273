import { SwapCurrent } from './navigator';
import { CharOnSelect } from './charselect';
import { RunUp } from './utils';

export default class Render extends HTMLElement {

  startX: number;
  startY: number;

  connectedCallback() {
    const act = this as HTMLElement;
    const delta = 6;
    
    act.addEventListener('mousedown', (e: MouseEvent) => {
      if( e.target ){
        this.startX = e.pageX;
        this.startY = e.pageY;
      }
    });

    act.addEventListener('mouseup', (e) => {
      const diffX = Math.abs(e.pageX - this.startX);
      const diffY = Math.abs(e.pageY - this.startY);
    
      if (diffX < delta && diffY < delta) {
        var block = RunUp(e.target as HTMLElement);
        if( block && block.id ){
          SwapCurrent(block, true);
          var studyForm = document.querySelector('app-studyform') as HTMLElement;
          if( studyForm ) studyForm.setAttribute("anneid", '');
        }else{
          return;
        }
      }else{
        CharOnSelect(window.getSelection());
      }
    });
  }
}