import { marked } from 'marked';
import { Annotation, AnnotationCollection, Tag } from '../types';


export function AnnotationGenerator(collector : AnnotationCollection[], tag: Tag){
  var zindex = collector.length+10;
  var output = '';
  for( var i = 0; i < collector.length; i++ ){
    var outputitems = '';
    var multihide = false;
    for( var j = 0; j < collector[i].items.length; j++){
      var bits = maxHeight(collector, i, j);
      var annooutput = '';
      var hideclass = '';
      if( bits.varHT ){
        annooutput += `<div class="anne" data-mxh="`+bits.mxh+`" style="`+bits.maxheight+`">`;
      }else{
        hideclass = ' hide';
        if(collector[i].items.length > 1 )multihide = true;
        annooutput += `<div class="anne" data-mxh="`+bits.mxh+`">`;
      }
      if( collector[i].items[j].a ){
        annooutput += marked.parse(collector[i].items[j].a.replace(/\n/g,'<br/>'));
      }
      if( collector[i].items[j].t && collector[i].items[j].t.length > 0 ){
        var anneqs = tag.preset ? `annotation=`+tag.preset+`&` : '';
        annooutput += `<div class="tags">`;
        for( var k = 0; k < collector[i].items[j].t.length; k++ ){
          annooutput += `<a href="?`+anneqs+`tags=`+collector[i].items[j].t[k]+`&"`+(tag.filter.indexOf(collector[i].items[j].t[k]) > -1 ? ' class="filtered"' : '')+` data-filter="`+collector[i].items[j].t[k]+`">#`+collector[i].items[j].t[k] + `</a> `;
        }
        annooutput += `</div>`;
      }
      annooutput += `</div>`;
      if( collector[i].items[j].au ) annooutput += `<div class="author">`+collector[i].items[j].au+`</div>`;
      var counterExpand = '';
      if( (multihide && j == 0) || !multihide){
        counterExpand = `<div class="noofannes" style="z-index:`+zindex+`">+`+collector[i].items.length+`</div>`;
      }
      outputitems += `<div tabindex="0" class="annotate`+hideclass+`" style="z-index:`+zindex+`" id="anne-`+collector[i].items[j].id+`" data-blockid="`+collector[i].items[j].bid+`" data-id="`+collector[i].items[j].id+`">`+counterExpand+annooutput+`</div>`;
    }
    output += `<div`+(multihide?' tabindex="0"':'')+` class="annect`+(multihide?' hideandseek':'')+`" id="annecol-`+collector[i].bid+`" style="top:`+collector[i].yoffset+`px;z-index:`+zindex+`">`+outputitems+`</div>`;
    zindex--;
  }
  return output;
}

function maxHeight( collector: AnnotationCollection[], i: number, j: number ){
  function variableHeightTest(mxh: number, anne: Annotation){
    if( mxh > 15 ){
      if( mxh > 200 ){
        return true;
      }else if( mxh > 70 ){
        return true;
      }else if( mxh > 60 && !anne.a && anne.t.length < 6){
        return true;
      }else if( mxh > 40 && !anne.a && anne.t.length < 5){
        return true;
      }else if( mxh > 35 && !anne.a && anne.t.length < 4){
        return true;
      }else if( mxh > 30 && !anne.a && anne.t.length < 3){
        return true;
      }else if( mxh > 19 && !anne.a && anne.t.length < 2){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  var mxh = i+1 < collector.length ? ((collector[i+1].yoffset - collector[i].yoffset) / collector[i].items.length) - 28 : 180;//28 being height of author label, 180 being suitable for footer
  var max_Height = 'max-height:'+mxh+'px;';
  return {
    mxh: mxh,
    maxheight: max_Height,
    varHT: variableHeightTest(mxh, collector[i].items[j])
  }
}
